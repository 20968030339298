
import axios from 'axios'
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import { queryCheckDetails, exportCheckDetails } from '@/api/advance.ts';
import { statusOption, auditStatusOption, statusMap, auditStatusMap, colorMap } from '@/utils/map';
import dayjs from 'dayjs'
import store from '@/store/index'
import cashInfo from '@/views/salary/management-salary/components/cash-info.vue'
import dialogCheckDetail from './components/dialog-check-detail.vue';

import infoStaffCheck from './components/info-staff-check.vue';
import { color } from 'echarts'

const fristDay = dayjs().startOf('month').format('YYYY-MM-DD')
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')
export default defineComponent({
    name: 'Bill',
    components: {
        searchComponent,
        tableComponent,
        cashInfo,
        dialogCheckDetail,
        infoStaffCheck
    },
    data() {
        return {
            searchList: {
                status: {
                    name: '预支状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    options: statusOption,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
                queryTime: {
                    name: '覆盖时间',
                    prop: 'queryTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [fristDay, lastDay],
                    defaultValue: [fristDay, lastDay],
                    unClearable: true
                }
            } as any,
            buttonList: [
                {
                    name: '导出明细',
                    icon: 'export',
                    type: '',
                    disabled: false,
                    fn: () => {
                        this.exporttable()
                    }
                }
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'serveCompanyName',
                    label: '所属企业',
                },
                {
                    prop: 'userName',
                    label: '负责人',
                },
                {
                    prop: 'payTime',
                    label: '发放时间',
                },
                {
                    prop: 'payAmount',
                    label: '发薪金额',
                    prefix: '¥',
                    defaultValue: '--',
                },
                {
                    prop: 'advanceAmount',
                    label: '预支金额',
                    prefix: '￥',
                    defaultValue: '--',
                },
                {
                    prop: 'status',
                    label: '操作状态',
                    map: statusMap,
                    colorMap: colorMap,
                },
                {
                    prop: 'remark',
                    label: '预支备注',
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            approvalId: '',
            selects: [],
            showDialog: false,
            cardId: '',
            infoData: {} as any
        }
    },
    activated() {
        if (this.$route.query?.id) {
            this.cardId = atob(this.$route.query.id as string)
            this.getData()
        }
    },
    mounted() {
        if (this.$route.query?.id) {
            this.cardId = atob(this.$route.query.id as string)
            this.getData()
        }
    },
    methods: {
        exporttable() {
            const query = this.createQuery()
            query.idCardNo = this.cardId
            query.ids = this.selects.join(',')
            exportCheckDetails(query).then(res => {
                window.location.href = process.env.VUE_APP_BASE_URL + '/common/download?delete=true&fileName=' + res
                ElMessage.success('导出成功！')
            })
        },
        getData() {
            const query = this.createQuery()
            query.idCardNo = this.cardId
            queryCheckDetails(query).then((res: any) => {
                this.tableData = res.list
                this.pageInfo.total = res.totalCount
                if (this.tableData?.length) {
                    this.infoData = this.tableData[0]
                    this.infoData.totalAdvanceAmount = res.totalAdvanceAmount
                    this.infoData.totalPayAmount = res.totalPayAmount
                    this.pageInfo.total = res.total
                }
            })
        },
        handleSelectionChange(row: any) {
            this.selects = row.map((item: any) => item.serveCompanyId)
        },
        createQuery() {
            const query = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize
            } as any
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`${this.searchList[key].prop}Start`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`${this.searchList[key].prop}End`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        handleArchive(row: any) {
            this.$router.push({
                path: '/bill/advanceDetail',
                query: {
                    serveCompanyId: btoa(row.serveCompanyId),
                    startTime: btoa(this.searchList.paySalaryDate.value[0]),
                    endTime: btoa(this.searchList.paySalaryDate.value[1])
                }
            })
        }
    }
})
