
import { defineComponent } from 'vue'
import { queryPaymentRecord } from '@/api/advance.ts';

export default defineComponent({
  components: {},
  props: {
    infoData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    setDefault() {
      return (value: string) => {
        return value ?? '0'
      }
    }
  },
})
